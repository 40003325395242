import React from 'react'
import styles from './Footer.module.scss'
import Image from 'next/image'
import { Container } from 'components/common/Containers/Container/Container'
import { FlexRow } from 'components/common/Containers/FlexRow/FlexRow'
import { FlexColumn } from 'components/common/Containers/FlexColumn/FlexColumn'
import { EmailForm } from './EmailForm/EmailForm'
import A from 'components/common/A/A'
import { RouteEnum } from '../../enums/RouteEnum'
import { FreeToolsData, ProToolsData } from 'components/Header/data'

interface Props {}

export const Footer: React.FC<Props> = ({}) => {
  const currentYear = new Date().getFullYear()
  return (
    <footer className={styles.container}>
      <Container className={styles.content}>
        <div className={styles.top}>
          <FlexColumn gap="10px" className={styles.left}>
            <div className={styles.leftContent}>
              <Image
                src="/logos/logo-light.svg"
                width={210}
                height={40}
                alt="logo"
              />
              <p>
                The Best AI-powered and advanced domain names tools on the web.
                DNS, domain names, name servers, IPs, and tools for searching
                and monitoring domain names.
              </p>
            </div>
          </FlexColumn>

          <EmailForm className={styles.right} />
        </div>

        <div className={styles.bottom}>
          <FlexColumn>
            <b>Free tools</b>
            <ul>
              {FreeToolsData.map((el) => (
                <li key={el.head}>
                  <A href={el.href}>{el.head}</A>
                </li>
              ))}
            </ul>
          </FlexColumn>

          <FlexColumn>
            <b>Pro tools</b>
            <ul>
              {ProToolsData.map((el) => (
                <li key={el.head}>
                  <A href={el.href}>{el.head}</A>
                </li>
              ))}
            </ul>
          </FlexColumn>

          <div>
            <FlexColumn>
              <b>Useful links</b>
              <ul>
                <li>
                  <A href={RouteEnum.PRICING}>Pricing</A>
                </li>
                <li>
                  <A href={RouteEnum.FAQ}>FAQs</A>
                </li>
                <li>
                  <a href="https://documenter.getpostman.com/view/11906748/2s93RUvCPw">API Documentation</a>
                </li>
                <li>
                  <A href={RouteEnum.BLOG}>Blog</A>
                </li>
              </ul>
            </FlexColumn>

            <FlexColumn className={styles.mt}>
              <b> Social Media</b>
              <ul>
                {/* <li>
                  <a href="https://bishopi.io">Facebook</a>
                </li> */}
                <li>
                  <a href="https://twitter.com/bishopi_io">Twitter</a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/bishopi/">Linkedin</a>
                </li>
                {/* <li>
                  <a href="https://bishopi.io">Discord</a>
                </li> */}
              </ul>
            </FlexColumn>
          </div>

          <FlexColumn>
            <b>Company</b>
            <ul>
              <li>
                <A href={RouteEnum.ABOUT}>About</A>
              </li>
              {/* <li>
                <a href="https://bishopi.io">Team</a>
              </li> */}
              {/* <li>
                <a href="https://bishopi.io">Jobs</a>
              </li> */}
              <li>
                <A href={RouteEnum.CONTACT_US}>Contact Us</A>
              </li>
            </ul>
          </FlexColumn>
        </div>

        <FlexColumn className={styles.signature}>
          <p>
            Copyright © {currentYear}. All rights reserved. Made with ❤️ in Netherlands
            🇳🇱
          </p>
          <FlexRow gap="8px">
            <A href={RouteEnum.TERMS_OF_USAGE}>Terms & Conditions</A>
            <span>|</span>
            <A href={RouteEnum.PRIVACY_POLICY}>Privacy Policy</A>
          </FlexRow>
        </FlexColumn>
      </Container>
    </footer>
  )
}
